@if (event && form && visual) {
  <div
    class="rsvp-dialog event-dialog-content"
    [ngStyle]="{
      'background-color': event.eventTemplate?.rsvpSettings?.backgroundColor,
      color: event.eventTemplate?.rsvpSettings?.bodyColor
    }"
    mat-dialog-content
    [formGroup]="form"
  >
    <div
      (click)="close()"
      class="close-button"
      [ngStyle]="{
        color: event.eventTemplate?.rsvpSettings?.backgroundColor
          ? Helpers.getTextColorForBg(
              event.eventTemplate?.rsvpSettings?.backgroundColor
            )
          : 'var(--text-primary)'
      }"
    >
      <i class="fal fa-times-circle"></i>
    </div>
    <div *ngIf="step === 1">
      <div
        class="dialog-body"
        [class.dialog-body-two-buttons]="layout === RSVPLayout.TWO_BUTTONS"
      >
        <div
          class="dialog-title mobile-only"
          [ngStyle]="{
            backgroundColor: event.eventTemplate?.rsvpSettings?.backgroundColor
              ? event.eventTemplate?.rsvpSettings?.backgroundColor
              : 'var(--body-bg-color)',
            color: event.eventTemplate?.rsvpSettings?.backgroundColor
              ? Helpers.getTextColorForBg(
                  event.eventTemplate?.rsvpSettings?.backgroundColor
                )
              : 'var(--text-primary)'
          }"
        >
          @if (isAttendee) {
            {{ "APP.EVENT_RSVP.EDIT_TITLE" | translate }}
          } @else {
            {{ "APP.EVENT_RSVP.TITLE" | translate }}
          }
        </div>
        @if (onWaitlist) {
          <div class="waitlist-wrapper">
            <h1 [innerHTML]="getRsvpDialogTitleWaitList() | safeHtml"></h1>
            <span
              [innerHTML]="getRsvpDialogDescriptionWaitList() | safeHtml"
            ></span>
          </div>
        } @else if (onPendingList) {
          <div class="waitlist-wrapper">
            <h1>{{ "APP.WAIT_LIST.REQUEST_RSVP" | translate }}</h1>
            <span>{{
              "APP.WAIT_LIST.REQUEST_RSVP_DESCRIPTION" | translate
            }}</span>
          </div>
        } @else if (notGoing && loggedUser) {
          <div
            class="waitlist-wrapper"
            [ngStyle]="{
              color: event.eventTemplate?.rsvpSettings?.backgroundColor
                ? Helpers.getTextColorForBg(
                    event.eventTemplate?.rsvpSettings?.backgroundColor
                  )
                : 'var(--text-primary)'
            }"
          >
            <h1>{{ "APP.EVENT_RSVP.CHANGED_YOUR_MIND" | translate }}</h1>
            <span
              [ngStyle]="{
                color: event.eventTemplate?.rsvpSettings?.backgroundColor
                  ? Helpers.getTextColorForBg(
                      event.eventTemplate?.rsvpSettings?.backgroundColor
                    )
                  : 'var(--text-primary)'
              }"
              >{{
                "APP.EVENT_RSVP.CHANGED_YOUR_MIND_DESCRIPTION" | translate
              }}</span
            >
          </div>
        } @else if (event.hasOneButtonSignUp() && yesRSVPOption) {
          <div class="rsvp-title-wrapper">
            <h1
              [innerHTML]="
                getRsvpDialogTitle(RSVPDialogStep.RSVP, yesRSVPOption.name)
                  | safeHtml
              "
            ></h1>
            <span
              [innerHTML]="
                getRsvpDialogDescription(RSVPDialogStep.RSVP) | safeHtml
              "
            ></span>
          </div>
        } @else {
          <div class="rsvp-title-wrapper">
            <h1
              [innerHTML]="getRsvpDialogTitle(RSVPDialogStep.RSVP) | safeHtml"
            ></h1>
            <span
              [innerHTML]="
                getRsvpDialogDescription(RSVPDialogStep.RSVP) | safeHtml
              "
            ></span>
          </div>
        }
        @if (
          event.rsvpOptions &&
          !onWaitlist &&
          !onPendingList &&
          !event.hasOneButtonSignUp() &&
          layout === RSVPLayout.THREE_SMILIES
        ) {
          <div class="rsvp-options">
            @for (rsvpOption of rsvpOptions; track rsvpOption.type) {
              <div
                class="rsvp-option"
                [class.selected]="selectedRSVPType === rsvpOption.type"
                (click)="onRSVPChange(rsvpOption.type)"
              >
                <div class="rsvp-emoji">{{ rsvpOption.emoji }}</div>
                <div class="rsvp-name">
                  {{ rsvpOption.name | translate }}
                </div>
              </div>
            }
          </div>
        }

        <div>
          @if (
            !loggedUser &&
            !event.eventTemplate?.rsvpSettings?.rsvpWithoutEmail &&
            !event.eventTemplate?.rsvpSettings?.rsvpWithoutPhone
          ) {
            <app-credentials-form-button-toggle
              [selectedOption]="selectedOption"
              (selectedOptionEvent)="selectOption($event)"
            >
            </app-credentials-form-button-toggle>
          }
          @if (event.eventTemplate?.rsvpSettings?.requireGroupRsvp) {
            <mat-form-field
              [appearance]="
                RSVPFormAppearance.getNameForFormAppearance(formAppearance)
              "
              class="w-100"
              [class.has-value]="form.controls['groupName'].value !== ''"
            >
              <mat-label>{{
                "APP.EVENT_RSVP.TEAM_NAME" | translate
              }}</mat-label>
              <input type="text" matInput formControlName="groupName" />
            </mat-form-field>
          }
          @if (!loggedUser) {
            @if (env.platformShort === "partypage") {
              <mat-form-field
                [appearance]="
                  RSVPFormAppearance.getNameForFormAppearance(formAppearance)
                "
                class="w-100"
                [class.has-value]="form.controls['name'].value !== ''"
              >
                <mat-label>
                  @if (event.uri === "woerthsee-vorschulturnen") {
                    {{ "APP.EVENT_RSVP.NAME" | translate }} ({{
                      "APP.EVENT_RSVP.PARENT" | translate
                    }})
                  } @else {
                    {{ "APP.EVENT_RSVP.NAME" | translate }}
                  }
                </mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="name"
                  autocomplete="given-name"
                />
              </mat-form-field>
            } @else {
              <mat-form-field
                [appearance]="
                  RSVPFormAppearance.getNameForFormAppearance(formAppearance)
                "
                class="w-100"
                [class.has-value]="form.controls['firstName'].value !== ''"
              >
                <mat-label> {{ "APP.FIRST_NAME" | translate }}* </mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="firstName"
                  autocomplete="given-name"
                />
              </mat-form-field>
              <mat-form-field
                [appearance]="
                  RSVPFormAppearance.getNameForFormAppearance(formAppearance)
                "
                class="w-100"
                [class.has-value]="form.controls['lastName'].value !== ''"
              >
                <mat-label> {{ "APP.LAST_NAME" | translate }}* </mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="lastName"
                  autocomplete="family-name"
                />
              </mat-form-field>
            }
          }

          <div class="flex-inputs-cont">
            @if (!loggedUser) {
              @if (selectedOption === "email") {
                <ng-container formGroupName="emailFormGroup">
                  <mat-form-field
                    [appearance]="
                      RSVPFormAppearance.getNameForFormAppearance(
                        formAppearance
                      )
                    "
                    class="flex-input email-input"
                    [class.has-value]="
                      emailFormGroup.controls['email'].value !== ''
                    "
                  >
                    <mat-label>{{ "APP.EMAIL" | translate }}*</mat-label>
                    <input
                      type="email"
                      matInput
                      formControlName="email"
                      autocomplete="username"
                    />
                  </mat-form-field>
                </ng-container>
              }
              <ng-container formGroupName="phoneFormGroup">
                <mat-form-field
                  class="w-100 phone-form"
                  [class.display-none]="selectedOption === 'email'"
                  [class.has-value]="
                    phoneFormGroup.controls['phone'].value !== ''
                  "
                  [appearance]="
                    RSVPFormAppearance.getNameForFormAppearance(formAppearance)
                  "
                >
                  <app-tel-input
                    #telInput
                    formControlName="phone"
                  ></app-tel-input>
                </mat-form-field>
              </ng-container>
            }

            <mat-form-field
              class="flex-input plus-ones-input"
              [appearance]="
                RSVPFormAppearance.getNameForFormAppearance(formAppearance)
              "
              [class.display-none]="
                selectedRSVPType === 'NO' ||
                !event.plusOnesLimit ||
                event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData()
              "
            >
              <mat-select formControlName="numAttendees">
                <mat-option [value]="0">
                  {{ "APP.EVENT_RSVP.NO_PLUS_ONES" | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let numAttendees of numAttendeesOptions"
                  [value]="numAttendees"
                  [class.has-value]="form.controls['numAttendees'].value !== ''"
                >
                  {{ "APP.EVENT_RSVP.PLUS" | translate }} {{ numAttendees }}
                  {{
                    (numAttendees === 1
                      ? "APP.EVENT_RSVP.ATTENDEE"
                      : "APP.EVENT_RSVP.ATTENDEES"
                    ) | translate
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          @if (!loggedUser && selectedOption === "email") {
            <div
              class="password-cont"
              [class.password-hidden]="accountWithEmailExists === undefined"
            >
              @if (accountWithEmailExists) {
                <div class="account-info">
                  {{ "APP.AUTH.RSVP_ACCOUNT_EXISTS" | translate }}
                </div>
              } @else if (accountWithEmailExists === false) {
                <div class="account-info">
                  {{ "APP.AUTH.RSVP_ACCOUNT_NOT_EXISTS" | translate }}
                </div>
              }

              <ng-container formGroupName="emailFormGroup">
                <mat-form-field
                  class="w-100"
                  [class.has-value]="
                    emailFormGroup.controls['password'].value !== ''
                  "
                  [appearance]="
                    RSVPFormAppearance.getNameForFormAppearance(formAppearance)
                  "
                  *ngIf="selectedOption === 'email'"
                >
                  <mat-label>{{ "APP.AUTH.PASSWORD" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    placeholder=""
                    type="{{ showPassword ? 'text' : 'password' }}"
                  />
                  <i
                    matSuffix
                    (click)="showPassword = !showPassword"
                    class="fal show-password-suffix"
                    [class.fa-eye]="!showPassword"
                    [class.fa-eye-slash]="showPassword"
                  ></i>
                  @if (emailFormGroup.controls["password"].hasError("custom")) {
                    <mat-error>
                      {{
                        emailFormGroup.controls["password"].getError("custom")
                      }}
                    </mat-error>
                  }
                </mat-form-field>
              </ng-container>

              @if (accountWithEmailExists) {
                <div class="auth-fallback-container account-info">
                  <span>{{ "APP.AUTH.FORGOT_PASSWORD" | translate }}</span
                  >&nbsp;
                  <span class="text-link" (click)="goToForgotPassword()">{{
                    "APP.AUTH.RESET_PASSWORD" | translate
                  }}</span>
                </div>
              } @else if (accountWithEmailExists === false) {
                <div class="terms-cont">
                  <div class="terms-cont-wrapper">
                    <ng-container formGroupName="emailFormGroup">
                      <mat-checkbox formControlName="acceptedTerms"
                        >{{ "APP.I_AGREE_TO" | translate }}
                        <a
                          href="https://eventpage.ai/terms-of-use/"
                          target="_blank"
                          >{{ "APP.THE_TERMS_OF_USE" | translate }}</a
                        ></mat-checkbox
                      >
                      <mat-checkbox formControlName="acceptedPrivacyPolicy"
                        >{{ "APP.I_CONSENT_TO" | translate }}
                        <a
                          href="https://eventpage.ai/datenschutzerklaerung/"
                          target="_blank"
                          >{{ "APP.THE_DATA_PRIVACY" | translate }}</a
                        ></mat-checkbox
                      >
                    </ng-container>
                  </div>
                </div>
              }
            </div>
          }

          <mat-form-field
            [appearance]="
              RSVPFormAppearance.getNameForFormAppearance(formAppearance)
            "
            class="w-100"
            [class.has-value]="form.controls['comment'].value !== ''"
          >
            <mat-label>{{
              (selectedRSVPType === "NO" ||
              onWaitlist ||
              onPendingList ||
              isSportpageEvent ||
              [
                "founders-fiesta",
                "eltern-kind-olympiade-2024",
                "woerthsee-vorschulturnen",
                "eventpageai-exit-party"
              ].includes(this.event.uri)
                ? "APP.EVENT_RSVP.ADD_MESSAGE"
                : "APP.EVENT_RSVP.POST_COMMENT"
              ) | translate
            }}</mat-label>
            <input type="text" matInput formControlName="comment" />
          </mat-form-field>

          @if (event.eventTemplate?.attendeeQuestions && !isAttendee) {
            @for (
              question of event.eventTemplate?.getAttendeeQuestionsSorted();
              track question.id
            ) {
              <app-event-rsvp-question
                [form]="form"
                [question]="question"
                matFormFieldClass="w-100"
                [matFormFieldAppearance]="
                  RSVPFormAppearance.getNameForFormAppearance(formAppearance)
                "
              ></app-event-rsvp-question>
            }
          }
        </div>
      </div>
      <div
        mat-dialog-actions
        class="dialog-actions"
        [class.two-buttons]="
          layout === RSVPLayout.TWO_BUTTONS &&
          event.type !== EventType.INVITATION &&
          isDesktop
        "
        [class.rsvp-no-account]="
          !loggedUser &&
          event.eventTemplate?.rsvpSettings?.rsvpWithoutAccount &&
          selectedOption === 'email' &&
          accountWithEmailExists === false
        "
      >
        @if (layout === RSVPLayout.TWO_BUTTONS) {
          @if (onWaitlist) {
            <div
              class="btn btn-primary pop-color"
              [ngStyle]="event.getRSVPSettingsButtonStyles()"
              [class.btn-invitation-transparent]="
                event.type === EventType.INVITATION
              "
              [style.background]="
                visual.backgroundColor ? visual.backgroundColor : ''
              "
              (click)="rsvp(RSVPOptionType.YES)"
            >
              {{
                (yesRSVPOption ? yesRSVPOption.name : "APP.AUTH.JOIN_NOW")
                  | translate
              }}
            </div>
          } @else if (isAttendee && notGoing) {
            <div
              class="btn btn-primary pop-color"
              [ngStyle]="event.getRSVPSettingsButtonStyles()"
              [class.btn-invitation-transparent]="
                event.type === EventType.INVITATION
              "
              [style.background]="
                visual.backgroundColor ? visual.backgroundColor : ''
              "
              (click)="rsvp(RSVPOptionType.NO)"
            >
              {{ "APP.EVENT_RSVP.UNSUBSCRIBE" | translate }}
            </div>
          } @else {
            @for (
              rsvpOption of event.getMainRsvpOptions();
              track rsvpOption.type
            ) {
              <div
                class="btn btn-primary pop-color"
                [ngStyle]="event.getRSVPSettingsButtonStyles()"
                [class.btn-invitation-transparent]="
                  event.type === EventType.INVITATION
                "
                [style.background]="
                  visual.backgroundColor ? visual.backgroundColor : ''
                "
                (click)="rsvp(rsvpOption.type)"
              >
                {{ rsvpOption.name | translate }}
              </div>
            }
          }
        } @else {
          <button
            class="btn btn-primary pop-color"
            [ngStyle]="event.getRSVPSettingsButtonStyles()"
            (click)="rsvp()"
            [class.spinner]="inProgress"
            [disabled]="inProgress"
          >
            @if (isAttendee) {
              @if (notGoing) {
                {{ "APP.EVENT_RSVP.UNSUBSCRIBE" | translate }}
              } @else {
                {{ "APP.EVENT_RSVP.UPDATE_RESPONSE" | translate }}
              }
            } @else {
              @if (event.hasOneButtonSignUp() && yesRSVPOption) {
                {{ "APP.AUTH.JOIN_NOW" | translate }}
              } @else {
                {{ "APP.CONTINUE" | translate }}
              }
            }
          </button>
          @if (
            !loggedUser &&
            event.eventTemplate?.rsvpSettings?.rsvpWithoutAccount &&
            selectedOption === "email" &&
            accountWithEmailExists === false
          ) {
            @if (isSportPage) {
              <button
                class="btn btn-primary-outline white-outline pop-color"
                (click)="rsvpWithoutAccount()"
                [disabled]="inProgressWithoutAccount"
                [ngStyle]="event.getRSVPSettingsButtonStyles()"
              >
                {{ "APP.AUTH.RSVP_WITHOUT_ACCOUNT" | translate }}
              </button>
            } @else {
              <div class="text-link" (click)="rsvpWithoutAccount()">
                {{ "APP.AUTH.RSVP_WITHOUT_ACCOUNT" | translate }}
              </div>
            }
          }
        }
      </div>
    </div>

    <div *ngIf="step === 2">
      <div *ngIf="selectedOption === 'phone'">
        <app-verify-phone-dialog
          [otpUserId]="otpUserId"
          [phone]="userPhone"
          [eventUuid]="event.uuid"
          [eventId]="event.id"
          [rsvpReq]="rsvpReq"
          [isRSVPwithOTP]="true"
          [isWrongCode]="isWrongCode"
          (changePhone)="changePhone()"
          (isSaveRSVPEvent)="onVerifyPhoneSuccess($event)"
        >
        </app-verify-phone-dialog>
      </div>

      @if (
        selectedOption === "email" && (loginRequest || isNoAccountRSVPVerify)
      ) {
        <app-verify-email-step
          [email]="loginRequest ? loginRequest.email : rsvpReq?.email"
          [userId]="otpUserId"
          [isDialogVerify]="true"
          [isNoAccountRSVPVerify]="isNoAccountRSVPVerify"
          [isWrongCode]="isNoAccountRSVPVerifyWrongCode"
          (verifyEmailSuccess)="onVerifyEmailSuccess()"
          (noAccountVerifyCodeCompleted)="
            onNoAccountVerifyCodeCompleted($event)
          "
          class="auth-step-page-center"
        />
      }
    </div>

    @if (step === 3) {
      <app-rsvp-plus-ones-step
        [event]="event"
        [form]="form"
        [loggedUser]="loggedUser"
        [layout]="layout"
        [formAppearance]="
          RSVPFormAppearance.getNameForFormAppearance(formAppearance)
        "
        [isAttendee]="isAttendee"
        [inProgress]="inProgress"
        (next)="onAdditionalPlusOnesDataNext()"
      />
    }
  </div>
}
